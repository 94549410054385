<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver
      v-slot="{ invalid }"
      ref="observer"
      tag="div"
      class="arttextstyle"
      id="scroll-block"
    >
      <header class="arttextstyle__header">
        <h3 class="arttextstyle__header_title">{{ arttextstyle.id ? "Edit" : "Add" }} Post</h3>
        <div class="arttextstyle__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete"
            @click="$emit('removeItem', arttextstyle)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid || arttextstyle.search_image === '' || !changed"
            @click="saveArttextstyle"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <h3 class="form__header">Post Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Name"
              :required="true"
              :validateError="errors[0] || serverError.title"
              v-model="arttextstyle.title"
              :element="arttextstyle.title"
              @input="arttextstyle.title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Author"
              :required="true"
              :validateError="errors[0]"
              v-model="arttextstyle.author"
              :element="arttextstyle.author"
              @input="arttextstyle.author = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <DatePicker
              placeholder="Date Published"
              :time="false"
              :required="true"
              :endDate="today"
              :validateError="errors[0]"
              :value="arttextstyle.date_published"
              v-model="arttextstyle.date_published"
              @input="arttextstyle.date_published = $event"
            ></DatePicker>
          </ValidationProvider>
        </div>
        <div class="form__row form__row--align-start">
          <div class="form__row_col form__row_col--side">
            <BaseSelect
              placeholder="Status"
              type="status"
              v-model="arttextstyle.status"
              :selected="arttextstyle.status"
              :items="[
                { id: 0, title: 'Draft' },
                { id: 1, title: 'Published' },
              ]"
              @input="arttextstyle.status = $event.id"
            ></BaseSelect>
          </div>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="arttextstyle.slug"
              :element="arttextstyle.slug"
              @input="arttextstyle.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text">
                  Example: {{ `${url}/media/arttextstyle/${arttextstyle.slug || "path"}` }}
                </span>
              </template>
            </BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Post Details</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <Editor
              placeholder="Text Editor"
              :required="true"
              :validateError="errors[0]"
              :value="arttextstyle.details"
              @input="arttextstyle.details = $event"
              v-model="arttextstyle.details"
            ></Editor>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-tag"
            v-model="arttextstyle.tags"
            :focusOpen="true"
            :loading="tagsLoading"
            :items="arttextstyle.tags"
            :matches="matches"
            placeholder="Tags"
            @addItem="addTag"
            @input="arttextstyle.tags = $event"
            @searchMatches="apiGetMatchedTags"
          ></MultipleAutocompleteInput>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Images</h3>
        <div class="form__row">
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label">
              Search Image (recommended size: 1500×1296px)
              <span class="form__row_label_required">*</span>
            </span>
            <DragAndDropImage
              class="uploader--full-width"
              id="search"
              size="15"
              :image="arttextstyle.search_image"
              :v-model="arttextstyle.search_image"
              @input="
                arttextstyle.search_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                arttextstyle.search_image = '';
              "
            ></DragAndDropImage>
          </div>
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label"> Header Image (recommended size: 2560×780px) </span>
            <DragAndDropImage
              class="uploader--full-width"
              id="header"
              size="15"
              :image="arttextstyle.header_image"
              :v-model="arttextstyle.header_image"
              @input="
                arttextstyle.header_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                arttextstyle.header_image = '';
              "
            ></DragAndDropImage>
          </div>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";
import Editor from "@/components/inputs/Editor.vue";
import DragAndDropImage from "@/components/DragAndDropImage.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    BaseButton,
    BaseSelect,
    BaseInput,
    BaseNumberInput,
    Editor,
    DatePicker,
    DragAndDropImage,
    MultipleAutocompleteInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      today: null,
      arttextstyle: {
        title: "",
        author: "",
        date_published: null,
        status: "0",
        slug: "",
        details: "",
        search_image: "",
        header_image: "",
        tags: [],
      },
      changed: false,
      removedImages: [],
    };
  },
  created() {
    this.today = this.$moment().tz("America/New_York").format("YYYY-MM-DD HH:mm");
    if (this.item != null) {
      this.arttextstyle = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("arttextstyle", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
    ...mapGetters("tags", {
      matches: "getMatchedTags",
      tagsLoading: "getChangesLoading",
    }),
  },
  watch: {
    arttextstyle: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (this.$refs.observer.flags.dirty) {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("tags", ["apiPostTag", "apiGetMatchedTags"]),
    ...mapMutations("events", ["setError"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid && this.arttextstyle.header_image != ""
              ? this.saveArttextstyle()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    async addTag(val) {
      let data = { title: val };
      let result = await this.apiPostTag(data);
      if (result.success) {
        this.arttextstyle.tags.push(result.tag);
        this.$emit("updateTag");
      } else {
        this.$toasted.show(result.error, {
          duration: 2000,
          type: "error",
        });
      }
    },
    saveArttextstyle() {
      let data = {
        ...this.arttextstyle,
        tags: this.arttextstyle.tags.map(el => {
          return el.id.toString();
        }),
      };
      if (this.arttextstyle.id) {
        this.$emit("updateItem", { data });
      } else {
        this.$emit("createItem", { data });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.arttextstyle {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      text-transform: capitalize;
      color: $black;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>
